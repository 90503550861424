<template>
  <div id="app">
    <mainSub></mainSub>
  </div>
</template>

<script>
import mainSub from './components/mainSub'

export default {
  name: 'App',
  components: {
    mainSub
  }
}
</script>

<style>
body{
  zoom: 80%; 
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0%;
}
</style>
