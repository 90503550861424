<template>
<!-- 主题大div -->
  <div class="bodyfont">
    <!-- 上半部分div -->
    <div style="width:100%">
        <!-- 顶部 -->
        <div class="topDiv">
            <img src="../assets/toplogo.png" alt="" class="topLogo">
            <div class="xsscFont">线上商城</div>
            <img src="../assets/erweima.png" alt="" class="erweima">
        </div>
        <div style="width:100%">
            <!-- 图片部分 -->
            <div class="picDiv">
                <img src="../assets/qiuyi2.png" alt="" class="qiuyi">
                
            </div>
        </div>
        <div class="iconDiv">
            <img src="../assets/topicon.png" alt="">
        </div>
    </div>
    <div class="bottomPart">
        <div class="bottomTag">
            <font class="mdlb">门店列表</font><br>
            <font class="storelist">STORE LIST</font>
        </div>
        <div class="mdTab">
            <!-- 门店详情列表部分 -->
            <div class="mdItem" v-for="(item,index) in mdLIst" :key="index">
                <p class="mdname">{{item.mdName}}<br>{{item.mdAddr}}</p>
                <div class="mdwz"> 
                    <img src="../assets/dd.png" alt="" class="ddicon">
                    <div class="mddzdiv">
                        <font class="mddz">{{item.mdDz}}</font>
                    </div>
                </div>
                <div class="sjdh">
                    <img src="../assets/time.png" alt="" class="sjicon">
                    <p class="Left mdtime">{{item.mdTime}}</p>
                    <img src="../assets/lfdh.png" alt="" class="sjicon lxdh">
                    <p class="mdtime">{{item.mdTel}}</p>
                </div>
                <div class="mdpic">
                    <img :src="require(`../assets/${item.mdImg}` )" alt="" class="mdpiture">
                </div>
                <div class="bottombt">
                    <img src="../assets/lxwm.png" alt="">
                    <img src="../assets/dh.png" alt="" class="btdh">
                </div>
            </div>
            <div class="fwyzdiv">
                    <h2 class="inputHead">防伪验证</h2>
                    <p class="inputHead2">ANTI-COUNTERFEITING VERIFICATION</p>
                    <input placeholder="请输入8位编码" class="inptstyle" v-model="barCode"/>
                    <button class="btnstyle" v-on:click="pushpage()">立即查询</button>
                </div>
        </div>
    </div>
    <!-- 底部 -->
    <div class="bottomblack">

    </div>
    <!-- 幕布 -->
    <div v-bind:class="[mbflag]">
        
    </div>
    
        <div class="pushpage" v-show="flag">
            <p class="pushhead" style="font-family: DINCOROS-Bold;letter-spacing: 0.1em;font-weight: 550;font-size: 35px;">VBEINGS HOLOGRAM LOOKUP</p>
            <img :src="productList.img" class="picimg">
            <!-- 验证码 -->
            <p class="pushhead2"><font style="font-family: DINCOROS-Bold;letter-spacing: 0.1em;font-weight: 550;font-size: 35px;">{{productList.code}}</font><br>{{productList.name}}</p>
        </div>
        <!-- 水印 -->
        <div class="shuiying" v-show="flag">
            <!-- 弹窗取消点击 -->
            <img src="../assets/cha.png" alt="" class="cha" v-on:click="cha()">
        </div>

    <!-- 验证码弹窗 -->
    
</div>
</template>

<script>
import axios from 'axios';

export default {
    name:"mainSub",
    data(){
        return{
            // 幕布显示标志
            mbflag:" ",
            // 弹窗显示标志
            flag:false,
            barCode: "",
            // 验证码
            yzm:"",
            productName:"",
            productUrl:"",
            productList: {},
            // 门店详情信息
            mdLIst:[
                {mdName:"VBEINGS微缤礼物",mdAddr:"长沙国金中心店",mdImg:"mdpicture01.jpg",mdDz:"湖南省长沙市芙蓉区长沙国金中心G层LG112-113",mdTime:"10:00-22:00",mdTel:"13548565437"},
                {mdName:"VBEINGS微缤礼物",mdAddr:"长沙运达汇店",mdImg:"mdpicture02.jpg",mdDz:"湖南省长沙市雨花区运达中央广场1层L136",mdTime:"10:00-22:00",mdTel:"13109960638"},
                {mdName:"VBEINGS微缤礼物",mdAddr:"长沙大悦城店",mdImg:"mdpicture03.jpg",mdDz:"湖南省长沙市开福区北辰大悦城北馆G层G09",mdTime:"10:00-22:00",mdTel:"18873720093"},
                {mdName:"VBEINGS微缤礼物",mdAddr:"长沙海信广场店",mdImg:"mdpicture04.jpg",mdDz:"湖南省长沙市天心区海信广场2层206",mdTime:"10:00-22:00",mdTel:"13739063302"},
                {mdName:"VBEINGS微缤礼物",mdAddr:"杭州中心店",mdImg:"mdpicture05.jpg",mdDz:"浙江省杭州市拱墅区杭州中心B1层B127",mdTime:"10:00-22:00",mdTel:"18164966064"},
                {mdName:"VBEINGS微缤礼物",mdAddr:"武汉SKP店",mdImg:"mdpicture06.jpg",mdDz:"湖北省武汉市武昌区沙湖大道18号SKP-GF层B1008",mdTime:"10:00-22:00",mdTel:"18627870303"},
                {mdName:"VBEINGS微缤礼物",mdAddr:"青岛海信广场店",mdImg:"mdpicture07.jpg",mdDz:"山东省青岛市市南区澳门路117号海信广场B1层",mdTime:"10:00-21:30",mdTel:"18561104060"},
                {mdName:"VBEINGS微缤礼物",mdAddr:"昆明恒隆广场店",mdImg:"mdpicture08.jpg",mdDz:"云南省昆明市盘龙区东风东路恒隆广场5层",mdTime:"10:00-22:00",mdTel:"18314428408"}
            ]
        }
    },
    created(){
        //console.log("执行到了") 
        let urlParams = new URLSearchParams(window.location.search);
        let barCode = urlParams.get('barcode');
        console.log(barCode); // 输出: vb000132
        if(barCode!= "" && barCode!= null)

        {
            this.barCode = barCode;
            this.pushpage()
        }
    },

    methods:{
        pushpage(){
            this.mbflag = "mbmaxDiv"
            var barCode=this.barCode;
            axios.get('http://www.vbgift.com:3688/ewem/product/wx/'+barCode, {
                params: {
                    //barCode: '',//请求参数
                },
            }).then(
                (res) => {
                    if(res.data.code == 200)
                    {
                        this.productList = res.data.data;
                        this.productList.img = "http://www.vbgift.com:3688" + this.productList.img;
                    }
                    else
                    {
                        this.productList = "";

                    }


                }
            )
            this.flag = !this.flag
            // console.log(this.mbflag)
        },
        cha(){
            this.mbflag = " "                          
            this.flag = !this.flag
            // console.log("flag")
        }
    }
}
</script>

<style>
@import '../assets/fonts/sourcehansans.css';
@font-face {
  font-family: "DINCOROS-Bold";
  src: url('../assets/fonts/DINCOROS-Bold-2.ttf');
  font-weight: normal;
  font-style: normal;
}
.bodyfont{
    font-family: 'Source Han Sans', sans-serif;
}

.tupian{
    width: 100%;
}
.Left{
    float: left;
}
.maxDiv{
    /* background-color: rgb(35, 35, 35); */
    /* opacity: 0.75; */
}
.mbmaxDiv{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgb(15, 15, 15);
    opacity: 0.2;
    
}
.picimg{
    width: 560px;
    height: auto;
    border-radius: 10px;
    margin-top: 20px;

}
/* 顶部div */
.topDiv{
    height: 90px;
}
.topLogo{
    height: 50px;
    margin-right: 1145px;
    margin-bottom: 10px;
}
/* 线下商城 */
.xsscFont{
    display: inline-block;
    height: 75px;
    font-weight: 550;
    margin-right: 10px;
    vertical-align: middle;
}
/* 二维码 */
.erweima{
    height: 70px;
    margin-top: 10px;
}
/* 球衣图片 */
.qiuyi{
    width: 100%;
    height: 350px;  
}
.picDiv{
    /* background-image: url(../assets/qiuyi.png);
    background-position-y: -105px;
    background-repeat: no-repeat;
    background-color: rgb(97, 131, 160); */
    width: 100%;
    height: 350px;
    margin: 0 auto;
}
.iconDiv{
    /* background-image: url(../assets/topicon.png); */
}
.bottomTag{
    padding-top: 25px;
    color: black;
}
.mdlb{
    font-size: 50px;
    font-weight: 600;
}
.storelist{
    font-size: 15px;
    font-weight: 600;
}
.mdTab{
    width: 1610px;
    /* background-color: cornflowerblue; */
    margin: 0px auto;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
}
.bottomPart{
    margin: 0 auto;
    background-color: rgb(246, 246, 246);
}
/* 门店列表详情 */
.mdItem{
    width: 321px;
    height: 460px;
    background-color: rgb(252, 251, 251);
    border-radius: 20px;
    padding: 15px 10px;
    text-align: left;
    margin-left: 50px;
    margin-top: 30px;
    
}
.mdname{
    font-family:"Source Han Sans","Microsoft YaHei";
    font-size: 26px;
    font-weight: 600;
    margin: 10px;
    color: black;
}
.ddicon{
    float: left;
    width: 16px;
    margin-left: 10px;
    margin-top: 4px;
}
.mddzdiv{
    float: left;
    margin-left: 5px;
    margin-top: 0px;
    width: 285px;
    /* background-color: rgb(138, 52, 52); */
}
.mddz{
    font-size: 19px;
    font-weight: 500;
}
.mdwz{
    color: rgb(114 112 112);
}
.sjdh{
    float: left;
    width: 350px;
    color: rgb(114 112 112);
    /* background-color: rgb(85, 21, 21); */
}
.sjicon{
    float: left;
    width: 16px;
    margin-left: 10px;
    margin-top: 12px;
}
.mdtime{
    float: left;
    margin-left: 2px;
    margin-top: 10px;
    font-size: 19px;
}
.lxdh{
    margin-left: 40px;
}
/* 门店详情图片 */
.mdpic{
    float: left;
    margin-left: 10px;
    width: 300px;
    height: 200px;
    border-radius: 15px;
    /* background-color: rgb(141, 42, 42); */
    /* background-image: url(../assets/mdpic.png); */
    background-position-y: -45px;
    background-position-x: -15px;
    background-size: 330px;
}
.mdpiture{
    width: 300px;
    height: 200px;
    border-radius: 15px;
}
.picstyle{
    width: 350px;
    height: 100px;
    
}
.bottombt{
    float: left;
    margin-left: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 302px;
    /* background-color: rgb(105, 223, 238); */
}
.btdh{
    float: right;
}
.bottomblack{
    width: 100%;
    height: 300px;
    background-color: rgb(252, 250, 250);
}
/* 防伪验证 */
.fwyzdiv{
    position: absolute;
    top:-724px;
    right: 55px;
    width: 600px;
    height: 465px;
    background-color:rgb(246, 246, 246);
    color: black;
}
.inputHead{
    font-size: 60px;
    margin-top: 50px;
}
.inputHead2{
    font-size: 18px;
    font-weight: 550;
    margin-top: -40px;
}
/* 验证码输入框 */
.inptstyle{
    width: 430px;
    height: 65px;
    border-radius: 5px;
    margin-top: 50px;
    font-size: 25px;
    box-sizing: border-box;
    color: rgb(68, 67, 67);
    text-align: center;
}

.inptstyle::placeholder {
  opacity: 0.5;
  color: rgb(112, 111, 111);
  margin-left: 10px;
  padding-left: 0 10px;
}
/* 点击查询按钮 */
.btnstyle{
    display: block;
    width: 433px;
    height: 65px;
    margin: 20px auto;
    border-radius: 5px;
    background-color: rgb(236, 61, 46);
    color: rgb(242, 233, 233);
    font-size: 25px;
}
/* 幕布 */
.mubu{
    width: 100%;
    height: 1000px;
    position: absolute;
    background-color: black;
}
/* 弹窗 */
.pushpage{
    width: 1200px;
    height: 900px;
    /* background-image: url(../assets/shuiying.png); */
    background-color: rgb(246, 246, 246);
    border-radius: 3px;
    position: absolute;
    top: 80px;
    left: 415px;
}
.pushhead{
    font-weight: 550;
    font-size: 35px;
    margin-top: 50px;
}
.yecard{
    width: 515px;
    height: 515px;
    border-radius: 5px;
    background-color: rgb(202, 178, 125);
    margin: 0 auto;
}
.pushhead2{
    font-family: 'Source Han Sans', 'Noto Sans CJK SC', 'HanHei SC', 'Microsoft YaHei', sans-serif;
    font-weight: 550;
    font-size: 35px;
    /* margin-top: 50px; */
}
/* 叉叉 */
.cha{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    left: 1100px;
    z-index: 1;
}
/* 水印 */
.shuiying{
    width: 1200px;
    height: 900px;
    background-image: url(../assets/shuiying.png);
    /* background-color: rgb(246, 246, 246); */
    border-radius: 3px;
    position: absolute;
    top: 80px;
    left: 415px;
}
</style>